import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import SingleGrant from "@hellodarwin/core/lib/components/grants/single";
import Loading from "@hellodarwin/core/lib/components/loading";
import GrantInquiryModal from "@hellodarwin/core/lib/components/modals/grant-inquiry-modal";
import { FormSubmissionResponse } from "@hellodarwin/core/lib/features/entities/form-entities";
import SingleGrantSections from "@hellodarwin/core/lib/features/enums/single-grant-sections";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import { useClientAnalytics } from "../../../features/analytics/use-client-analytics";
import {
  fetchFormSubmissionByGrantId,
  fetchGrantQualificationForm,
  selectActiveForm,
  selectActiveFormSubmissions,
  selectGrantById,
} from "../../../features/api/slices/grants-slice";
import { selectContact } from "../../../features/api/slices/profile-slice";
import { fetchGrant } from "../../../features/api/slices/programs-slice";
import { useClientApi } from "../../../features/api/use-client-api";

const ClientFundingExplorerSingleGrantPage = () => {
  const params = useParams<{
    id: string;
  }>();

  const { t } = useTranslation();
  const { selectedLocale } = useLocale();
  const theme = useTheme();
  const analytics = useClientAnalytics();

  const api = useClientApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const handleModal = () => setModalOpen(!modalOpen);

  const handModalOpen = () => {
    analytics.grantApplyNowClicked({ clicked_grant_id: grant.grant_id });
    handleModal();
  };

  const activeForm = useAppSelector(selectActiveForm);
  const activeFormSubmissions = useAppSelector(selectActiveFormSubmissions);
  const grant = useAppSelector((state) =>
    selectGrantById(state, params.id || "")
  );
  const contact = useAppSelector(selectContact);

  useEffect(() => {
    if (!params.id) return;

    dispatch(
      fetchGrant({
        api,
        grantId: params.id,
        locale: selectedLocale,
      })
    );

    dispatch(
      fetchGrantQualificationForm({
        api,
        grant_id: params.id,
        locale: selectedLocale,
      })
    );
    dispatch(
      fetchFormSubmissionByGrantId({
        api,
        grant_id: params.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  const goToFundingExplorer = () => {
    navigate("/funding-explorer");
  };

  const handleSaveSubmission = async (submission: FormSubmissionResponse) => {
    submission.submission.grant_id = grant.grant_id;
    api.createFormSubmission(submission);
  };

  if (!params.id) return <Loading />;

  return (
    <PageLayout
      app="client"
      tabTitle={grant?.grant_display_title}
      title={grant?.grant_display_title}
      handleBack={goToFundingExplorer}
      isLoading={!grant}
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.fundingExplorer"),
          path: "/funding-explorer",
        },
        {
          breadcrumbName: !grant
            ? t("grants|singleGrant")
            : grant?.grant_display_title,
        },
      ]}
    >
      <div style={{ maxWidth: theme.caps.normal, width: "100%" }}>
        <SingleGrant
          grant={grant}
          sections={[
            SingleGrantSections.Summary,
            SingleGrantSections.Preview,
            SingleGrantSections.Projects,
            SingleGrantSections.Admissibility,
            SingleGrantSections.Criteria,
            SingleGrantSections.Register,
            SingleGrantSections.OtherInfo,
            SingleGrantSections.Documents,
            SingleGrantSections.Contact,
          ]}
          alreadyApplied={!!activeFormSubmissions.length}
          onClick={handModalOpen}
        />{" "}
      </div>
      <GrantInquiryModal
        source="client"
        handleSaveSubmission={handleSaveSubmission}
        open={modalOpen}
        handleCancel={handleModal}
        relatedFormResponse={activeForm}
        pageName={grant?.grant_title ?? "Client Grant Single"}
        contact={contact}
      />
    </PageLayout>
  );
};

export default ClientFundingExplorerSingleGrantPage;

