import AppLayout from "@hellodarwin/core/lib/components/common/layout/app-layout";
import ErrorNotFound from "@hellodarwin/core/lib/components/error/notfound";
import { ProjectStatusFlowSteps } from "@hellodarwin/core/lib/components/project/status-flow";
import useDebounceFunction from "@hellodarwin/core/lib/features/hooks/use-debounce-function";
import { useAppData } from "@hellodarwin/core/lib/features/providers/app-provider";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useLogin } from "@hellodarwin/core/lib/features/providers/login-provider";
import useUTM from "@hellodarwin/core/lib/features/providers/utm-provider";
import { SegmentAnalytics } from "@segment/analytics.js-core";
import { useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";

import { useAppDispatch, useAppSelector } from "./app/index";
import ProjectActionModal from "./components/project/project-action-modal";
import ProviderActionModal from "./components/provider/provider-action-modal";
import {
  me,
  selectProfile,
  selectProfileLoading,
} from "./features/api/slices/profile-slice";
import { useClientApi } from "./features/api/use-client-api";
import BottomNavigation from "./layout/bottom-navigation";
import Navigation from "./layout/navigation";
import PageHeader from "./layout/page-header";
import ClientHomePage from "./pages";
import FAQClientPage from "./pages/faq";
import ClientFundingExplorer from "./pages/funding-explorer/client-funding-explorer-page";
import ClientFundingExplorerSingleGrantPage from "./pages/funding-explorer/single-grant/client-funding-explorer-single-grant";
import ClientOngoingProgramsPage from "./pages/program-pages/client-programs-page/client-roadmap-programs-page";
import ClientSingleMilestonePage from "./pages/program-pages/client-single-program-page/client-single-milestone-page";
import ClientSingleRoadmapProgram from "./pages/program-pages/client-single-program-page/client-single-roadmap-program";
import ClientProjectsPage from "./pages/projects";
import ClientSingleProjectPage from "./pages/projects/[projectId]";
import ClientSingleProviderPage from "./pages/projects/[projectId]/providers/[matchId]";
import ClientProjectStatusFlowPage from "./pages/projects/[projectId]/status";
import ClientResourcesPage from "./pages/resources";
import ClientResourcesSinglePage from "./pages/resources/[resourceSlug]";
import ClientReviewsPage from "./pages/reviews";
import ClientSettingsPage from "./pages/settings";

import "./App.scss";

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    _hsq: any;
  }
}

const ProjectRedirect = ({ tab }: { tab: string }) => {
  const { projectId } = useParams();
  return <Navigate to={`/projects/${projectId}/${tab}`} />;
};
const ProgramsRedirect = () => {
  const { programId } = useParams();
  return <Navigate to={`/programs/${programId}`} />;
};
const MilestonesRedirect = () => {
  const { milestoneId } = useParams();
  return <Navigate to={`/programs/milestones/${milestoneId}`} />;
};

const ClientApp = () => {
  const dispatch = useAppDispatch();
  const api = useClientApi();
  const utmSource = useUTM();

  const { accessToken } = useLogin();
  const { pathname, hash, key } = useLocation();
  const { changeLanguage } = useLocale();

  const profile = useAppSelector(selectProfile);
  const isHelloLoading = useAppSelector((state) =>
    selectProfileLoading(state, "hello")
  );

  const appData = useAppData();
  const isSimpleDesign = useMemo(() => pathname.includes("status"), [pathname]);
  const isLoggedIn = useMemo(() => !!profile.contact_id, [profile]);

  const isAppLoading = useMemo(
    () => isHelloLoading || !isLoggedIn,
    [isHelloLoading, isLoggedIn]
  );

  //HASH
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "" && window !== undefined) {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  useEffect(() => {
    if (profile?.contact_email) {
      try {
        var _hsq = (window._hsq = window._hsq || []);
        _hsq?.push([
          "identify",
          {
            email: profile.contact_email,
          },
        ]);
      } catch (e: any) {
        console.error(e);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.contact_email]);

  useEffect(() => {
    const { setHeader, setNav, setBottomNav } = appData;
    if (!isSimpleDesign) {
      setHeader(<PageHeader />);
      setNav(<Navigation />);
      setBottomNav(<BottomNavigation />);
    } else {
      setHeader(<PageHeader />);
      setNav(<Navigation />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isSimpleDesign]);

  //--- PROFILE

  const fetchClient = useDebounceFunction(() => {
    dispatch(me({ api, utm: utmSource }));
  }, 300);

  useEffect(() => {
    if (accessToken === "") return;
    fetchClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (!!profile.contact_preferred_language && profile.contact_id) {
      changeLanguage(profile.contact_preferred_language);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <>
      <AppLayout app={"client"} isLoading={isAppLoading}>
        <Routes>
          {/* Home Page */}
          <Route path="/" element={<ClientHomePage />} />

          {/* Projects Page */}
          <Route path="/projects" element={<ClientProjectsPage />} />

          {/* Single Project Page */}
          <Route
            path="/projects/:projectId"
            element={<ClientSingleProjectPage defaultTab={"overview"} />}
          />
          <Route
            path="/projects/:projectId/project"
            element={<ProjectRedirect tab="brief" />}
          />
          <Route
            path="/projects/:projectId/brief"
            element={<ClientSingleProjectPage defaultTab={"brief"} />}
          />
          <Route
            path="/projects/:projectId/matches"
            element={<ProjectRedirect tab="candidates" />}
          />
          <Route
            path="/projects/:projectId/candidates"
            element={<ClientSingleProjectPage defaultTab={"candidates"} />}
          />
          <Route
            path="/projects/:projectId/messages"
            element={<ClientSingleProjectPage defaultTab={"messages"} />}
          />

          {/* Single Provider Page */}

          <Route
            path="/projects/:projectId/providers/:matchId"
            element={<ClientSingleProviderPage />}
          />

          {/* Resources Page */}
          <Route path="/resources" element={<ClientResourcesPage />} />
          <Route
            path="/resources/:resourceSlug"
            element={<ClientResourcesSinglePage />}
          />

          {/* Reviews Page */}
          <Route path="/reviews" element={<ClientReviewsPage />} />

          {/* Profile Page */}
          <Route
            path="/profile"
            element={<Navigate to={"/profile/contact"} />}
          />
          <Route
            path="/profile/contact"
            element={<ClientSettingsPage defaultActiveKey="contact" />}
          />
          <Route
            path="/profile/company"
            element={<ClientSettingsPage defaultActiveKey="company" />}
          />
          <Route
            path="/profile/users"
            element={<ClientSettingsPage defaultActiveKey="users" />}
          />

          {/* Faq Page */}
          <Route path="/faq" element={<FAQClientPage />} />

          {/* Project Status Flow Page */}
          <Route
            path="/projects/:projectId/status"
            element={<ClientProjectStatusFlowPage />}
          />
          <Route
            path="/projects/:projectId/status/cancel"
            element={
              <ClientProjectStatusFlowPage
                initialStep={ProjectStatusFlowSteps.CancelProjectFlow}
              />
            }
          />
          <Route
            path="/projects/:projectId/status/chosen-sp"
            element={
              <ClientProjectStatusFlowPage
                initialStep={ProjectStatusFlowSteps.PartnerChooseSelected}
              />
            }
          />
          <Route
            path="/projects/:projectId/status/contact-manager"
            element={
              <ClientProjectStatusFlowPage
                initialStep={ProjectStatusFlowSteps.NoPartnerSelectedOptions}
              />
            }
          />

          {/* Single Program Page */}

          <Route
            path="/propulsion"
            element={<Navigate to="/programs/roadmap" />}
          />
          <Route
            path="/programs"
            element={<Navigate to={"/programs/roadmap"} />}
          />
          <Route
            path="/programs/all"
            element={<Navigate to={"/programs/roadmap"} />}
          />
          <Route
            path="/programs/roadmap"
            element={<ClientOngoingProgramsPage />}
          />
          <Route
            path="/programs/digital-transformations"
            element={<Navigate to={"/programs/roadmap"} />}
          />

          <Route path="/propulsion/:programId" element={<ProgramsRedirect />} />
          <Route
            path="/programs/:programId"
            element={<ClientSingleRoadmapProgram />}
          />

          <Route
            path="/propulsion/milestones/:milestoneId"
            element={<MilestonesRedirect />}
          />
          <Route
            path="/programs/milestones/:milestoneId"
            element={<ClientSingleMilestonePage />}
          />

          {/* Funding Explorer */}
          <Route path="/funding-explorer" element={<ClientFundingExplorer />} />
          <Route
            path="/funding-explorer/:id"
            element={<ClientFundingExplorerSingleGrantPage />}
          />

          {/* Error Page */}
          <Route path="*" element={<ErrorNotFound />} />
        </Routes>
      </AppLayout>
      <ProjectActionModal />
      <ProviderActionModal />
    </>
  );
};

export default ClientApp;

